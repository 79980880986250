import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/sustainfm/Hero";
import Content1 from "../sections/sustainfm/Content1";
import Feature2 from "../sections/sustainfm/Feature2";
import Content2 from "../sections/sustainfm/Content2";
import Conts1 from "../sections/sustainfm/Conts1";
import Fact from "../sections/sustainfm/Fact";
import Conts2 from "../sections/sustainfm/Conts2";

const sutainfamng = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <Conts1 />
        <Content1 />
        <Fact />
        <Content2 />
        <Conts2 />
        <Feature2 />
      </PageWrapper>
    </>
  );
};
export default sutainfamng;
