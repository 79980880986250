import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Text, Section, Box, Span, A } from "../../components/Core";

const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={55}
      minWidth={55}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
      mr={3}
    >
      <i className={`icon ${iconName}`}></i>
    </Box>

    <Title variant="card" mb={0}>
      {title}
    </Title>
  </Box>
);

const Feature2 = () => (
  <>
    <Section bg="#f7f7fb">
      <Container>
        <Row className="justify-content-center">
          <Col lg="9">
            <Box className="text-center" mb={[4, 5]}>
              <Title>
                Advantages of Sustainable
                <br />
                Facility Management Software
              </Title>
            </Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="zoom-in"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="50"
          >
            <ContentCard
              title="Have peace of mind knowing you’re doing your bit to protect the Earth"
              color="ash"
              iconName="icon-layout-11"
            />
          </Col>
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="zoom-in"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="50"
          >
            <ContentCard
              title="Reduce costs on wasted energy, equipment downtime, and preventable replacements"
              color="primary"
              iconName="icon-cards-2"
            />
          </Col>
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="zoom-in"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="50"
          >
            <ContentCard
              title="Have all your processes run under one, cloud-based management software solution"
              color="warning"
              iconName="icon-phone-charging-3-2"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="zoom-in"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="50"
          >
            <ContentCard
              title="Optimize all operations"
              color="ash"
              iconName="icon-bookmark-2-2"
            />
          </Col>
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="zoom-in"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="50"
          >
            <ContentCard
              title="Get data-driven insights and performance indicators"
              color="success"
              iconName="icon-voice-recognition-2"
            />
          </Col>
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="zoom-in"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="50"
          >
            <ContentCard
              title="Get more customers due to sustainable practices"
              color="primary"
              iconName="icon-infinite"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Text>
            With our advanced, fully-integrated facility management software
            solutions, you’ll have a comprehensive control panel where you can
            keep track of all your sustainability practices. Our goal is to
            provide best-in-class software, so you can provide best-in-class
            services to your customers.
            <br className="d-none d-md-block" />
            When it comes to sustainability – regardless of your industry – you
            want to do it properly. Using facility management software means you
            can have full control over each operation while maximizing your
            savings at the same time.
            <br className="d-none d-md-block" />
            <Span color="secondary">
              {" "}
              Though change and adaptability may be a scary concept, facility
              management software provides an all-encompassing solution to both
              adaptability and overall management. If a slight dip in
              productivity in the short-term brings proliferated productivity in
              the long-term, wouldn’t your business be unquestionably destined
              for optimal success for years to come?
            </Span>{" "}
            <br className="d-none d-md-block" />
            <Span color="secondary">
              To further ease transitions, Napa Vertical dedicates its expertise
              and innovation to help you swiftly understand and adapt to the
              latest technology we offer.
            </Span>
            <br className="d-none d-md-block" /> Napa Vertical is here to help
            you invest in more sustainable practices not only for your own
            business, but for the world.
            <Span color="secondary">
              {" "}
              Your business is bound for success with key sustainability
              practices and integrations, so why not take full advantage of
              every innovative resource to do so?
            </Span>
            <br className="d-none d-md-block" /> With innovative software
            technology, excellent customer support, user-friendly systems, and
            measurable outcomes, Napa Vertical aims to help you incorporate
            innovative solutions – every time.
            <br className="d-none d-md-block" />{" "}
            <Span color="secondary">
              So grab the prospect by the horns, make a difference, and show the
              world just how efficient and victorious your legacy can become.
              We’ll be on standby ensuring everything goes according to plan.{" "}
              <br className="d-none d-md-block" />
              Invest in sustainability and manage through Napa Vertical.{" "}
              <A href="cmms-sl">Click here </A>
              for more information about integrating CMMS software into your
              sustainability practices.
            </Span>
          </Text>
        </Row>
      </Container>
    </Section>
  </>
);

export default Feature2;
