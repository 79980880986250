import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgC1 from "../../assets/image/jpeg/l7-content2-image-1.jpg";
import imgC2 from "../../assets/image/jpeg/l7-content2-image-2.jpg";
import imgC3 from "../../assets/image/jpeg/l7-content2-image-3.jpg";
import imgC4 from "../../assets/image/jpeg/l7-content2-image-4.jpg";

const SectionStyled = styled(Section)``;

const SingleImage = styled(Box)`
  display: flex;
  flex-direction: column;
  img {
    padding-bottom: 15px;
    padding-right: 15px;
    border-radius: 8px;
  }
`;

const Content2 = () => {
  return (
    <>
      {/* <!-- Content2 section --> */}
      <SectionStyled pt="0!important">
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-6 mb-lg-0">
              <Box className="d-flex">
                <SingleImage>
                  <img
                    src={imgC1}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-once="true"
                  />
                  <img
                    src={imgC2}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="500"
                    data-aos-once="true"
                  />
                </SingleImage>
                <SingleImage>
                  <img
                    src={imgC3}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="1000"
                    data-aos-once="true"
                  />
                  <img
                    src={imgC4}
                    alt=""
                    className="img-fluid"
                    data-aos="zoom-in"
                    data-aos-duration="750"
                    data-aos-delay="1500"
                    data-aos-once="true"
                  />
                </SingleImage>
              </Box>
            </Col>
            <Col lg="6" md={9}>
              <div>
                <Title>What Does This Mean?</Title>
                <Text>
                  You can use our scheduling software to analyze which
                  departments use which resources and for how long. From here,
                  you can modify heating or cooling systems, energy use, and
                  even unlock and lock doors with a fully integrated system at
                  your fingertips.
                  <br className="d-none d-md-block" /> If you have an event’s
                  hall that is only used four or five times a year, simply use
                  your facility management software to turn off energy
                  consumption in the hall, while keeping the lights on in the
                  other, busier areas of your facility. By using our innovative
                  facility management software scheduling system, you can
                  reserve spaces, automatically respond, and manage virtually
                  any space around your facility.
                  <br className="d-none d-md-block" /> Furthermore, these
                  software solutions sync with your equipment, giving you alerts
                  on preventive maintenance tasks, potential malfunctions, and
                  general operating conditions. This ensures all systems are
                  working at their highest capacities with minimal downtime,
                  increased costs, and fully sustainable equipment.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content2;
