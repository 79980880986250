import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  background-color: #f7f7fb;
  border-bottom: 1px solid #ededf4;
  padding-bottom: 30px;
  padding-top: 0;
  @media ${device.md} {
    padding-bottom: 70px;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f7f7fb" pt={0}>
      <Container>
        <div className="content-center">
          <Text>
            Take a look at some data-driven statistics. According to Maryville
            University:
          </Text>
        </div>
        <Row className="text-center">
          <Col md="4" className="mb-5">
            <Box>
              <Title color="secondary">87%</Title>
              <Text>
                of American consumers support businesses who advocate causes
                they care about
              </Text>
            </Box>
          </Col>
          <Col md="4" className="mb-5">
            <Box>
              <Title color="secondary">76%</Title>
              <Text>
                of Americans expect business to contribute to climate change
              </Text>
            </Box>
          </Col>
          <Col md="4" className="mb-5">
            <Box>
              <Title color="secondary">73%</Title>
              <Text>
                of Americans would stop supporting businesses who disregard
                climate change
              </Text>
            </Box>
          </Col>
        </Row>
        <div className="content-center">
          <Text>
            Let’s say you’ve decided to integrate sustainability practices into
            all of your departments. Where will you learn about the latest
            trends or even begin to manage the system once launched?
            <Span color="secondary">
              {" "}
              How can you truly stand your ground and make a difference while
              taking care of business at the same time?
            </Span>
          </Text>
        </div>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
